import { Button, Input, Radio, Space, Form, Upload } from 'antd'
import React, { useState } from 'react'

import { PlusOutlined, InboxOutlined } from '@ant-design/icons'
import axios from 'axios'
import { Media_URL } from '../../../utils/config'

const { Dragger } = Upload

const FileForm = ({
  handleCancel,
  addModuleItem,
  loadingUpload,
  setFormActive
}) => {
  const [form] = Form.useForm()

  const handleSubmit = (values) => {
    const body = new FormData()
    body.append('file', values.file[0].originFileObj)
    body.append('type', 'file')
    body.append('title', values.title)
    addModuleItem(body).then(() => setFormActive(false))
  }

  const getFileList = (files) => {
    return files.fileList
  }

  const handleAutoFillName = (value) => {
    form.setFieldsValue({ ...form.getFieldValue(), title: value.file.name })
  }

  return (
    <>
      <Form
        form={form}
        onFinish={handleSubmit}
        style={{ marginTop: '8px', width: '100%' }}
        requiredMark="optional"
      >
        <Space>
          <Form.Item
            name="title"
            label="File Name"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button onClick={handleCancel}>Cancel</Button>
          </Form.Item>
          <Form.Item>
            <Button
              loading={loadingUpload}
              type="primary"
              onClick={() => form.submit()}
            >
              submit
            </Button>
          </Form.Item>
        </Space>
        <Form.Item
          name="file"
          valuePropName="fileList"
          getValueFromEvent={getFileList}
          rules={[
            { type: 'array', max: 1, required: true, message: 'only one file' }
          ]}
        >
          <Dragger
            onChange={handleAutoFillName}
            beforeUpload={() => {
              return false
            }}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">
              Support for a single file upload only.
            </p>
          </Dragger>
        </Form.Item>
      </Form>
    </>
  )
}

// const FileForm2 = ({
//   handleCancel,
//   addModuleItem,
//   loadingUpload,
//   setFormActive,
// }) => {
//   const [form, setForm] = useState({ title: '', file: null, thumbnail: null });

//   console.log("file MMMMMMMM", form.file)

//   const handleSubmit = async (event) => {
//     event.preventDefault(); // Prevent default form submission behavior
//     const formData = new FormData();
//     formData.append('video', form.file);
//     formData.append('thumbnail', form.thumbnail);

//     try {
//       const config = {
//         onUploadProgress: (progressEvent) => {
//           const percentCompleted = Math.round(
//             (progressEvent.loaded * 100) / progressEvent.total
//           );
//           console.log("percentCompleted", percentCompleted)
//         },
//       };

//       const response = await axios.post("http://localhost:4000/upload", formData, config);

//       console.log("responce", response)

//       if (response.data) {
//              const  moduleItem = {
//                 'thumbnail' : response.data.data.thumbnail.url,
//                 'videoUrl': response.data.data.video.url,
//                 'type': "file",
//                 'title': form.title,
//               }
          
//               moduleItem.type = 'video'
//               addModuleItem(moduleItem).then(() => setFormActive(false))
//       }
//     } catch (error) {
//       console.error("Upload Error:", error);
//     } finally {
//     }
    
//   };

//   const handleFileChange = (event) => {
//     setForm({ ...form, file: event.target.files[0], title: event.target.files[0].name });

//   };

  
//   const handlethumbnailChange = (event) => {
//     setForm({ ...form, thumbnail: event.target.files[0] });
//   };
  

//   return (
//     <form onSubmit={handleSubmit}>
//       <div style={{ marginTop: '8px', width: '100%' }}>
//         <div style={{ display: "flex", gap: 30 }}>
//           <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
//             <label htmlFor="fileName">File Name:</label>
//             <input
//               id="fileName"
//               type="text"
//               value={form.title}
//               onChange={(e) => setForm({ ...form, title: e.target.value })}
//               className="shadow-sm w-60 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
//               required
//             />
//           </div>
//           <br />
//           {/* <label htmlFor="fileInput">File:</label>
//         <input
//           id="fileInput"
//           type="file"
//           onChange={handleFileChange}
//           required
//         /> */}
//           <div>
//             <input
//               id="thumbnail"
//               type="file"
//               onChange={handlethumbnailChange}
//               accept="image/*"
//               style={{ display: "none" }}
//               required
//             //  className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
//             />
//             <label
//               htmlFor="thumbnail"
//               className="w-60 py-2 px-3 cursor-pointer flex items-center space-x-4 rounded-lg border border-gray-200 bg-primary-700 hover:bg-primary-800 hover:text-blue-700 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 "
//             >
//               <svg
//                 className="w-4 h-4 mr-2 -ml-1"
//                 fill="currentColor"
//                 viewBox="0 0 20 20"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path d="M5.5 13a3.5 3.5 0 01-.369-6.98 4 4 0 117.753-1.977A4.5 4.5 0 1113.5 13H11V9.413l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13H5.5z"></path>
//                 <path d="M9 13h2v5a1 1 0 11-2 0v-5z"></path>
//               </svg>
//               Upload thumbnail
//             </label>
//             {form.thumbnail && <img
//               className="my-4 rounded-lg w-44 h-28 sm:mb-0 xl:mb-4 2xl:mb-0"
//               src={
//                 URL.createObjectURL(form.thumbnail)
//               }
//               alt="picture"
//             />}
//           </div>
//         </div>

//         <div style={{ marginTop: 20}}>
//             <input
//               id="file"
//               type="file"
//               onChange={handleFileChange}
//               accept="video/*"
//               style={{ display: "none" }}
//               required
//             //  className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
//             />
//             <label
//               htmlFor="file"
//               className="w-60 py-2 px-3 cursor-pointer flex items-center space-x-4 rounded-lg border border-gray-200 bg-primary-700 hover:bg-primary-800 hover:text-blue-700 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 "
//             >
//               <svg
//                 className="w-4 h-4 mr-2 -ml-1"
//                 fill="currentColor"
//                 viewBox="0 0 20 20"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path d="M5.5 13a3.5 3.5 0 01-.369-6.98 4 4 0 117.753-1.977A4.5 4.5 0 1113.5 13H11V9.413l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13H5.5z"></path>
//                 <path d="M9 13h2v5a1 1 0 11-2 0v-5z"></path>
//               </svg>
//               Upload video
//             </label>
//             {form.file && 
//                 <video
//                   src={ URL.createObjectURL(form.file)}
//                   alt={form.file.name}
//                   width={100}
//                   height={100}
//                   // autoPlay
//                   controls
//                   muted
//                   className="mt-5 h-auto w-60 object-cover rounded-md"
//                 />
//               }
//           </div>

//         <div style={{ display: "flex", justifyContent: "flex-end"}}>
//         <button type="button" onClick={handleCancel}
//             className={`text-black border  ml-5 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800`}
//         >
//           Cancel
//         </button>
//         <button type="submit" disabled={loadingUpload}
//            className={`text-white bg-sky-500 ml-5 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800`}
//         >
//           {loadingUpload ? 'Uploading...' : 'Submit'}
//         </button>
//         </div>
//       </div>
//     </form>
//   );
// };



const FileForm2 = ({
  handleCancel,
  addModuleItem,
  loadingUpload,
  setFormActive,
}) => {
  const [form, setForm] = useState({ title: "", file: null, thumbnail: null });
  const [progress, setProgress] = useState(0); // State for tracking upload progress
  const [uploading, setUploading] = useState(false); // State for showing upload status

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior

    if (!form.file || !form.thumbnail) {
      alert("Please select both video and thumbnail files.");
      return;
    }

    const formData = new FormData();
    formData.append("video", form.file);
    formData.append("thumbnail", form.thumbnail);

    try {
      setUploading(true); // Start uploading state
      const config = {
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setProgress(percentCompleted); // Update progress state
        },
      };

      const response = await axios.post(`${Media_URL}/upload`, formData, config);

      if (response.data) {
        const moduleItem = {
          thumbnail: response.data.data.thumbnail.url,
          videoUrl: response.data.data.video.url,
          type: "file",
          title: form.title,
        };

        addModuleItem(moduleItem).then(() => setFormActive(false));
      }
    } catch (error) {
      console.error("Upload Error:", error);
      alert("Failed to upload files. Please try again.");
    } finally {
      setUploading(false); // Reset uploading state
      setProgress(0); // Reset progress after completion
    }
  };

  const handleFileChange = (event) => {
    setForm({ ...form, file: event.target.files[0], title: event.target.files[0].name });
  };

  const handleThumbnailChange = (event) => {
    setForm({ ...form, thumbnail: event.target.files[0] });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div style={{ marginTop: "8px", width: "100%" }}>
        {/* Title Input */}
        <div style={{ display: "flex", gap: 30 }}>
          <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
            <label htmlFor="fileName">File Name:</label>
            <input
              id="fileName"
              type="text"
              value={form.title}
              onChange={(e) => setForm({ ...form, title: e.target.value })}
              className="shadow-sm w-60 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              required
            />
          </div>
        </div>

        {/* Thumbnail Upload */}
        <div style={{ marginTop: "20px" }}>
          <input
            id="thumbnail"
            type="file"
            onChange={handleThumbnailChange}
            accept="image/*"
            style={{ display: "none" }}
            required
          />
          <label
            htmlFor="thumbnail"
            className="w-60 py-2 px-3 cursor-pointer flex items-center space-x-4 rounded-lg border border-gray-200 bg-primary-700 hover:bg-primary-800 hover:text-blue-700 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
          >
            <svg
              className="w-4 h-4 mr-2 -ml-1"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M5.5 13a3.5 3.5 0 01-.369-6.98 4 4 0 117.753-1.977A4.5 4.5 0 1113.5 13H11V9.413l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13H5.5z"></path>
              <path d="M9 13h2v5a1 1 0 11-2 0v-5z"></path>
            </svg>
            Upload Thumbnail
          </label>
          {form.thumbnail && (
            <img
              className="my-4 rounded-lg w-44 h-28 sm:mb-0 xl:mb-4 2xl:mb-0"
              src={URL.createObjectURL(form.thumbnail)}
              alt="thumbnail"
            />
          )}
        </div>

        {/* Video Upload */}
        <div style={{ marginTop: "20px" }}>
          <input
            id="file"
            type="file"
            onChange={handleFileChange}
            accept="video/*"
            style={{ display: "none" }}
            required
          />
          <label
            htmlFor="file"
            className="w-60 py-2 px-3 cursor-pointer flex items-center space-x-4 rounded-lg border border-gray-200 bg-primary-700 hover:bg-primary-800 hover:text-blue-700 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
          >
            <svg
              className="w-4 h-4 mr-2 -ml-1"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M5.5 13a3.5 3.5 0 01-.369-6.98 4 4 0 117.753-1.977A4.5 4.5 0 1113.5 13H11V9.413l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13H5.5z"></path>
              <path d="M9 13h2v5a1 1 0 11-2 0v-5z"></path>
            </svg>
            Upload Video
          </label>
          {form.file && (
            <video
              src={URL.createObjectURL(form.file)}
              alt={form.file.name}
              width={100}
              height={100}
              // controls
              muted
              className="mt-5 h-auto w-60 object-cover rounded-md"
            />
          )}
        </div>

        {/* Progress Bar */}
        {uploading && (
          <div style={{ marginTop: "20px" }}>
            <div
              style={{
                width: "100%",
                height: "20px",
                backgroundColor: "#f3f3f3",
                borderRadius: "5px",
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  width: `${progress}%`,
                  height: "100%",
                  backgroundColor: "#4caf50",
                }}
              ></div>
            </div>
            <p>{progress}%</p>
          </div>
        )}

        {/* Buttons */}
        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
          <button
            type="button"
            onClick={handleCancel}
            className="text-black border ml-5 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={loadingUpload || uploading}
            className="text-white bg-sky-500 ml-5 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
          >
            {uploading ? "Uploading..." : "Submit"}
          </button>
        </div>
      </div>
    </form>
  );
};

const PdfForm = ({
  handleCancel,
  addModuleItem,
  loadingUpload,
  setFormActive,
}) => {
  const [form, setForm] = useState({ title: '', file: null, thumbnail: null });

  console.log("file MMMMMMMM", form.file)

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent default form submission behavior
    const formData = new FormData();
    formData.append('type', 'pdf');
    formData.append('title', form.title);
    formData.append('file', form.file);
    addModuleItem(formData).then(() => setFormActive(false));
  };

  // const handleFileChange = (event) => {
  //   setForm({ ...form, file: event.target.files[0], title: event.target.files[0].name });
  //   // Update title only if needed (e.g., on initial selection)
  //   // document.getElementById('fileName').value = event.target.files[0].name;

  // };

  
  const handlePDFChange = (event) => {
    setForm({ ...form, file: event.target.files[0], title: event.target.files[0].name  });
  };
  

  return (
    <form onSubmit={handleSubmit}>
      <div style={{ marginTop: '8px', width: '100%' }}>
        <div style={{ display: "flex", gap: 30 }}>
          <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
            <label htmlFor="fileName">File Name:</label>
            <input
              id="fileName"
              type="text"
              value={form.title}
              onChange={(e) => setForm({ ...form, title: e.target.value })}
              className="shadow-sm w-60 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              required
            />
          </div>
          <br />
          {/* <label htmlFor="fileInput">File:</label>
        <input
          id="fileInput"
          type="file"
          onChange={handleFileChange}
          required
        /> */}
          <div>
            <input
              id="pdf"
              type="file"
              onChange={handlePDFChange}
              accept="application/pdf"
              style={{ display: "none" }}
              required
            //  className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
            />
            <label
              htmlFor="pdf"
              className="w-60 py-2 px-3 cursor-pointer flex items-center space-x-4 rounded-lg border border-gray-200 bg-primary-700 hover:bg-primary-800 hover:text-blue-700 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 "
            >
              <svg
                className="w-4 h-4 mr-2 -ml-1"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M5.5 13a3.5 3.5 0 01-.369-6.98 4 4 0 117.753-1.977A4.5 4.5 0 1113.5 13H11V9.413l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13H5.5z"></path>
                <path d="M9 13h2v5a1 1 0 11-2 0v-5z"></path>
              </svg>
              Upload PDF
            </label>
            <div>
             {form.title}
            </div>
          </div>
        </div>

        {/* <div style={{ marginTop: 20}}>
            <input
              id="file"
              type="file"
              onChange={handleFileChange}
              accept="video/*"
              style={{ display: "none" }}
              required
            //  className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
            />
            <label
              htmlFor="file"
              className="w-60 py-2 px-3 cursor-pointer flex items-center space-x-4 rounded-lg border border-gray-200 bg-primary-700 hover:bg-primary-800 hover:text-blue-700 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 "
            >
              <svg
                className="w-4 h-4 mr-2 -ml-1"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M5.5 13a3.5 3.5 0 01-.369-6.98 4 4 0 117.753-1.977A4.5 4.5 0 1113.5 13H11V9.413l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13H5.5z"></path>
                <path d="M9 13h2v5a1 1 0 11-2 0v-5z"></path>
              </svg>
              Upload video
            </label>
            {form.file && 
                <video
                  src={ URL.createObjectURL(form.file)}
                  alt={form.file.name}
                  width={100}
                  height={100}
                  autoPlay
                  controls
                  muted
                  className="mt-5 h-auto w-60 object-cover rounded-md"
                />
              }
          </div> */}

        <div style={{ display: "flex", justifyContent: "flex-end"}}>
        <button type="button" onClick={handleCancel}
            className={`text-black border  ml-5 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800`}
        >
          Cancel
        </button>
        <button type="submit" disabled={loadingUpload}
           className={`text-white bg-sky-500 ml-5 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800`}
        >
          {loadingUpload ? 'Uploading...' : 'Submit'}
        </button>
        </div>
      </div>
    </form>
  );
};


const VideoForm = ({ handleCancel, addModuleItem, setFormActive }) => {
  const [form] = Form.useForm()

  const handleSubmit = (moduleItem) => {
    moduleItem.type = 'video'
    addModuleItem(moduleItem).then(() => setFormActive(false))
  }

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      style={{ marginTop: '8px' }}
      requiredMark="optional"
    >
      <Space size={'middle'}>
        <Form.Item
          name="title"
          label="Video Title"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: 'Please valid url',
              type: 'url'
            }
          ]}
          name="url"
          label="Video url"
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button onClick={handleCancel}>Cancel</Button>
        </Form.Item>
        <Form.Item>
          <Button onClick={form.submit} type="primary">
            Submit
          </Button>
        </Form.Item>
      </Space>
    </Form>
  )
}

const ModuleItemForm = ({ instructorAccess, addModuleItem, loadingUpload }) => {
  const [formActive, setFormActive] = useState(false)
  const [moduleItemType, setModuleItemType] = useState('video')

  const handleCancel = () => setFormActive(false)

  if (!instructorAccess) return null

  return (
    <>
      {!formActive && (
        <div style={{ marginTop: '16px' }}>
          <Button icon={<PlusOutlined />} onClick={() => setFormActive(true)}>
            Add Item
          </Button>
        </div>
      )}
      {formActive && (
        <>
          <div
            style={{
              paddingBottom: '16px',
              borderBottom: '0px',
              marginTop: '16px'
            }}
          >
            <Radio.Group
              defaultValue="video"
              buttonStyle="solid"
              value={moduleItemType}
              onChange={(e) => setModuleItemType(e.target.value)}
            >
              <Radio.Button value="video">Video</Radio.Button>
              <Radio.Button value="file">paied video</Radio.Button>
              <Radio.Button value="pdf">pdf</Radio.Button>
            </Radio.Group>
          </div>
          {moduleItemType === 'video' && (
            <VideoForm
              addModuleItem={addModuleItem}
              handleCancel={handleCancel}
              setFormActive={setFormActive}
            />
          )}
          {moduleItemType === 'file' && (
            <FileForm2
              addModuleItem={addModuleItem}
              handleCancel={handleCancel}
              loadingUpload={loadingUpload}
              setFormActive={setFormActive}
            />
          )}
          {moduleItemType === 'pdf' && (
            <PdfForm
              addModuleItem={addModuleItem}
              handleCancel={handleCancel}
              loadingUpload={loadingUpload}
              setFormActive={setFormActive}
            />
          )}
        </>
      )}
    </>
  )
}

export default ModuleItemForm
