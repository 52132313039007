import React, { useState, useEffect } from "react";
import { Button2 } from "../components";
import { useSelector, useDispatch } from "react-redux";
import avatar from "../data/avatar.jpg";
import profile from '../data/profile.png';
import { useStateContext } from "../contexts/ContextProvider";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { updateUser, changepassword } from "../redux/actions/authAction";
import { Header } from '../components'
import { GLOBALTYPES } from "../redux/actions/globalTypes";
import { createHeader, createQuestion, createYear } from "../redux/actions/questionAction";
import { IoIosCloseCircle } from "react-icons/io";
 
const Add_Exam = () => {

    const { currentColor } =useStateContext();

    const { auth } = useSelector((state) => state);
    const dispatch = useDispatch();    

    // const [yearData, setYearData] = useState({});
    // const {
    //      subject,
    //      year,
    //      number_of_Questions,
    //      time_allowed,
    // } = yearData;

    const [yearData, setYearData] = useState({
            subject: '',
            year: '',
            test_date: null,
            exam: true, 
            number_of_Questions: 0,
            time_allowed: '',
        });
    
        const {
            subject,
            year,
            test_date,
            exam, 
            number_of_Questions,
            time_allowed,
        } = yearData;


    const [questionData, setQuestionData] = useState({});
    const {
        question_id,
         question,
         option1,
         option2,
         option3,
         option4,
         answer,
         explanation,
         grade,
         unit,
    } = questionData;

    const [headerData, setHeaderData] = useState({});
    const {
        before_QuestionId,
        header1,
        header2,
        paragraph,
    } = headerData;



    const [selectedImage, setSelectedImage ] = useState('');
     const [explanationImage, setExplanationImage ] = useState('');


    const handleChangeYearInput = (e) => {
        const { name, value } = e.target;
        setYearData({ ...yearData, [name]: value });
    };

    const handleChangeQuestionInput = (e) => {
        const { name, value } = e.target;
        setQuestionData({ ...questionData, [name]: value });
    };

    const handleHeaderInput = (e) => {
        const { name, value } = e.target;
        setHeaderData({ ...headerData, [name]: value });
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0]; // Access the selected image file
        setSelectedImage(file);

        setQuestionData({ ...questionData, question_pic: event.target.files[0] });
    };

    const handleExplanationImage = (event) => {
        const file = event.target.files[0]; 
        setExplanationImage(file);

        setQuestionData({ ...questionData, explanation_pic: event.target.files[0] });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!subject){
            dispatch({
                type: GLOBALTYPES.ALERT,
                payload: { error: "Select Subject" },
              });
              return;
        }

        dispatch(createYear({ yearData, auth}));
           
      };

      const removeFile = name => {
        if (name == 'selectedImage'){
            setSelectedImage('')
        } else if (name = 'explanationImage'){
          setExplanationImage('')
        }
      } 

    const handleSubmitQuestion = (e) => {
        e.preventDefault();
        if (!subject){
            dispatch({
                type: GLOBALTYPES.ALERT,
                payload: { error: "Select Subject" },
              });
              return;
        }
        if (!year){
            dispatch({
                type: GLOBALTYPES.ALERT,
                payload: { error: "insert exam year" },
              });
              return;
        }

        const data = { ...questionData, subject: subject, year:year };

        dispatch(createQuestion({ data, auth}));
           
      };

    const handleHeaderSubmit = (e) => {
        e.preventDefault();
        if (!header1 && !header2 && !paragraph){
            dispatch({
                type: GLOBALTYPES.ALERT,
                payload: { error: "insert a header" },
              });
              return;
        }

        const data = { ...headerData, subject: subject, year:year };

        dispatch(createHeader({data, auth}));
    }
      
  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <div className="">
        <div className="flex gap-10">
          <Header category="Page" title="Add Exam" />
          </div>

                    <div className="p-4 mb-10 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800">
                        <h3 className="mb-4 text-xl font-semibold dark:text-white">
                            Add Year
                        </h3>
                        <form onSubmit={handleSubmit}>
                            <div className="grid grid-cols-6 gap-6">
                                <div className="col-span-6 sm:col-span-3">
                                    <label
                                        for="year"
                                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                    >
                                        Exam year
                                    </label>
                                    <input
                                        type="number"
                                        name="year"
                                        id="year"
                                        onChange={handleChangeYearInput}
                                        value={year}
                                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                        placeholder="2000"
                                        required
                                    />
                                </div>
                                <div class="col-span-6 sm:col-span-3">
                                <label
                                        for="subject"
                                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                    >
                                        Subject
                                    </label> 
                                      <select id="subject"
                                        name='subject'
                                        onChange={handleChangeYearInput}
                                        value={subject}
                                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500">
                                        <option selected="">{subject ? subject : "Select Subject"}</option>
                                        <option value="Biology">Biology</option>
                                        <option value="Chemistry">Chemistry</option>
                                        <option value="Maths">Maths</option>
                                        <option value="English">English</option>
                                        <option value="Physics">Physics</option>
                                        <option value="Geography">Geography</option>
                                        <option value="History">History</option>
                                        <option value="Civics">Civics</option>
                                    </select>
                                </div>
                                <div className="col-span-6 sm:col-span-3">
                                    <label
                                        for="number_of_Questions"
                                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                    >
                                        Number of Questions
                                    </label>
                                    <input
                                        type="number"
                                        name="number_of_Questions"
                                        id="number_of_Questions"
                                        onChange={handleChangeYearInput}
                                        value={number_of_Questions}
                                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                        placeholder="60"
                                        required
                                    />
                                </div>

                                <div className="col-span-6 sm:col-span-3">
                                    <label
                                        for="time_allowed"
                                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                    >
                                        Time_Allowed
                                    </label>
                                    <input
                                        type="text"
                                        name="time_allowed"
                                        id="time_allowed"
                                        onChange={handleChangeYearInput}
                                        value={time_allowed}
                                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                        placeholder="60 minute"
                                        required
                                    />
                                </div>
                                
                                <div className="col-span-6 sm:col-full">
                                    <button
                                        style={{ backgroundColor: currentColor }}
                                        className={`text-white  hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800`}
                                        type="submit"
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>

          <div className="p-4 mb-10 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800">
                        <h3 className="mb-4 text-xl font-semibold dark:text-white">
                            Add Question
                        </h3>
                        <form onSubmit={handleSubmitQuestion}>
                            <div className="grid grid-cols-6 gap-6">
                            <div className="col-span-1 sm:col-span-1">
                                    <label
                                        for="question_id"
                                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                    >
                                        Question Id
                                    </label>
                                    <input
                                        type="number"
                                        name="question_id"
                                        id="question_id"
                                        onChange={handleChangeQuestionInput}
                                        value={question_id}
                                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                        // placeholder="2000"
                                        required
                                    />
                                </div>

                               <div className="col-span-12 sm:col-span-6">
                                    <label
                                        for="question"
                                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                    >
                                        Question
                                    </label>
                                    <textarea
                                        name="question"
                                        id="question"
                                        onChange={handleChangeQuestionInput}
                                        value={question}
                                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                        // placeholder="2000"
                                        required
                                    />
                                </div>

                                <div className="col-span-12 sm:col-span-6">
                                 <input id="question_pic" type="file" onChange={handleImageChange}
                                    accept="image/*" style={{display: "none"}}
                                    className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                                  />
                                  <label htmlFor="question_pic" className="cursor-pointer flex items-center space-x-4">
                                    <svg className="w-4 h-4 mr-2 -ml-1" fill="currentColor"
                                       viewBox="0 0 20 20"
                                       xmlns="http://www.w3.org/2000/svg">
                                    <path
                                     d="M5.5 13a3.5 3.5 0 01-.369-6.98 4 4 0 117.753-1.977A4.5 4.5 0 1113.5 13H11V9.413l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13H5.5z"></path>   
                                    <path
                                      d="M9 13h2v5a1 1 0 11-2 0v-5z"></path>
                                    </svg>
                                      Upload Question picture
                                  </label>
                                

                                <div className='relative my-4  w-28 h-auto sm:mb-0 xl:mb-4 2xl:mb-0'>
                                  {selectedImage && <>
                                    <img
                                    src={URL.createObjectURL(selectedImage)}
                                className="rounded-lg"
                                alt="Selected Image"
                            />
                            <button
                 type='button'
                 className='absolute -top-3 -right-3 bg-white rounded-full flex justify-center items-center'
                onClick={() => removeFile('selectedImage')}
              >
                <IoIosCloseCircle style={{ fontSize: "25px", color: "red" }} />
              </button>
              </>}
              </div>
              </div>


                              <div className="col-span-6 sm:col-span-3">
                                    <label
                                        for="option1"
                                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                    >
                                        Option 1
                                    </label>
                                    <input
                                        type="text"
                                        name="option1"
                                        id="option1"
                                        onChange={handleChangeQuestionInput}
                                        value={option1}
                                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                        // placeholder="2000"
                                        required
                                    />
                                </div>

                                <div className="col-span-6 sm:col-span-3">
                                    <label
                                        for="option2"
                                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                    >
                                        Option 2
                                    </label>
                                    <input
                                        type="text"
                                        name="option2"
                                        id="option2"
                                        onChange={handleChangeQuestionInput}
                                        value={option2}
                                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                        // placeholder="2000"
                                        required
                                    />
                                </div>

                                <div className="col-span-6 sm:col-span-3">
                                    <label
                                        for="option1"
                                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                    >
                                        Option 3
                                    </label>
                                    <input
                                        type="text"
                                        name="option3"
                                        id="option3"
                                        onChange={handleChangeQuestionInput}
                                        value={option3}
                                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                        // placeholder="2000"
                                        required
                                    />
                                </div>

                                <div className="col-span-6 sm:col-span-3">
                                    <label
                                        for="option4"
                                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                    >
                                        Option 4
                                    </label>
                                    <input
                                        type="text"
                                        name="option4"
                                        id="option4"
                                        onChange={handleChangeQuestionInput}
                                        value={option4}
                                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                        // placeholder="2000"
                                        required
                                    />
                                </div>

                                <div class="col-span-3 sm:col-span-2">
                                <label
                                        for="answer"
                                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                    >
                                        Answer
                                    </label> 
                                      <select id="answer"
                                        name='answer'
                                        onChange={handleChangeQuestionInput}
                                        value={answer}
                                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500">
                                        <option selected="">{answer ? answer : "Select Answer"}</option>
                                        <option value="option1">option1</option>
                                        <option value="option2">option2</option>
                                        <option value="option3">option3</option>
                                        <option value="option4">option4</option>
                                    </select>
                                </div>

                                <div className="col-span-12 sm:col-span-6">
                                    <label
                                        for="explanation"
                                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                    >
                                        Explanation
                                    </label>
                                    <textarea
                                        name="explanation"
                                        id="explanation"
                                        onChange={handleChangeQuestionInput}
                                        value={explanation}
                                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                        // placeholder="2000"
                                        // required
                                    />
                                </div>

                                <div className="col-span-12 sm:col-span-6">
                                 <input id="explanationImage" type="file" onChange={handleExplanationImage}
                                    accept="image/*" style={{display: "none"}}
                                    className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                                  />
                                  <label htmlFor="explanationImage" className="cursor-pointer flex items-center space-x-4">
                                    <svg className="w-4 h-4 mr-2 -ml-1" fill="currentColor"
                                       viewBox="0 0 20 20"
                                       xmlns="http://www.w3.org/2000/svg">
                                    <path
                                     d="M5.5 13a3.5 3.5 0 01-.369-6.98 4 4 0 117.753-1.977A4.5 4.5 0 1113.5 13H11V9.413l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13H5.5z"></path>   
                                    <path
                                      d="M9 13h2v5a1 1 0 11-2 0v-5z"></path>
                                    </svg>
                                      Upload explanation picture
                                  </label>
                            <div className='relative my-4  w-28 h-auto sm:mb-0 xl:mb-4 2xl:mb-0'>
                                  {explanationImage && <>
                                    <img
                                    src={URL.createObjectURL(explanationImage)}
                                className="rounded-lg"
                                alt="Selected Image"
                            />
                            <button
                 type='button'
                 className='absolute -top-3 -right-3 bg-white rounded-full flex justify-center items-center'
                onClick={() => removeFile('explanationImage')}
              >
                <IoIosCloseCircle style={{ fontSize: "25px", color: "red" }} />
              </button>
              </>}
              </div>
                                </div>

                                <div className="col-span-1 ">
                                    <label
                                        for="grade"
                                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                    >
                                        Grade
                                    </label>
                                    <input
                                        type="number"
                                        name="grade"
                                        id="grade"
                                        onChange={handleChangeQuestionInput}
                                        value={grade}
                                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                        
                                    />
                                </div>

                                <div className="col-span-1 ">
                                    <label
                                        for="unit"
                                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                    >
                                        Unit
                                    </label>
                                    <input
                                        type="number"
                                        name="unit"
                                        id="unit"
                                        onChange={handleChangeQuestionInput}
                                        value={unit}
                                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                      
                                    />
                                </div>
                                
                                
                                <div className="col-span-6 sm:col-full">
                                    <button
                                        style={{ backgroundColor: currentColor }}
                                        className={`text-white  hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800`}
                                        type="submit"
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800">
                        <h3 className="mb-4 text-xl font-semibold dark:text-white">
                            Add header
                        </h3>
                        <form onSubmit={handleHeaderSubmit}>
                            <div className="grid grid-cols-6 gap-6">
                            <div className="col-span-1 sm:col-span-1">
                                    <label
                                        for="before_QuestionId"
                                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                    >
                                      Before Question Id
                                    </label>
                                    <input
                                        type="number"
                                        name="before_QuestionId"
                                        id="before_QuestionId"
                                        onChange={handleHeaderInput}
                                        value={before_QuestionId}
                                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                        // placeholder="2000"
                                        required
                                    />
                                </div>

                               <div className="col-span-12 sm:col-span-6">
                                    <label
                                        for="header1"
                                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                    >
                                        Header 1
                                    </label>
                                    <input
                                        type="text"
                                        name="header1"
                                        id="header1"
                                        onChange={handleHeaderInput}
                                        value={header1}
                                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                        // placeholder="2000"
                                        // required
                                    />
                                </div>

                                <div className="col-span-12 sm:col-span-6">
                                    <label
                                        for="header2"
                                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                    >
                                        Header 2
                                    </label>
                                    <input
                                        type="text"
                                        name="header2"
                                        id="header2"
                                        onChange={handleHeaderInput}
                                        value={header2}
                                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                        // placeholder="2000"
                                        // required
                                    />
                                </div>

                             

                                <div className="col-span-12 sm:col-span-6">
                                    <label
                                        for="paragraph"
                                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                    >
                                        Paragraph
                                    </label>
                                    <textarea
                                        name="paragraph"
                                        id="paragraph"
                                        onChange={handleHeaderInput}
                                        value={paragraph}
                                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                        // placeholder="2000"
                                        // required
                                    />
                                </div>
                                
                                
                                <div className="col-span-6 sm:col-full">
                                    <button
                                        style={{ backgroundColor: currentColor }}
                                        className={`text-white  hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800`}
                                        type="submit"
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
        </div>
    </div>
  )
}

export default Add_Exam