import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Typography, Button, Modal, Form, Input, List } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { FlexSectionHeader } from '../../edu-hub/style'

import { STUDENT } from '../../edu-hub/constants/userRoles'
import ModuleList from './components/ModuleList'
// import { modules } from '../../data/course_data'

import {
  getAllModules,
  createModule,
  updateModule,
  deleteModule,
  createModuleItem,
  deleteModuleItem,
  clearModules,
  enrollUser,
  createCoursePrice,
  multipleEnrollUser
} from '../../redux2/reducers/moduleReducer'

import useCoursePrivilege from '../../edu-hub/hooks/useCourseprivilege'
import { Link, useParams } from "react-router-dom";

    


const Modules = (props) => {
  const { Title } = Typography

  const modules = useSelector((state) => state.modules)
  const { enrolled, privilege } = useCoursePrivilege()

  const token = useSelector((state) => state.auth.token)
  const uploadProgress = useSelector(state => state.upload.uploadModule)


  const { courseId } = useParams();

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAllModules(courseId))

    // return () => dispatch(clearModules())
  }, [courseId, dispatch])

  const addModule = (module) => {
    console.log("CCC fffff", token )
    dispatch(createModule(courseId, module, token))
  }

  const addCourseEnroll = (email) => {
    console.log("++++A addCourseEnroll",email.email , email.multiple_email)

    // dispatch(multipleEnrollUser(courseId, email, token))

    if(email.multiple_email){
      dispatch(multipleEnrollUser(courseId, email, token))
    }
    else if (email.email){
      dispatch(enrollUser(courseId, email, token))
    }
  }

  const addCourseFile = (module) => {
    console.log("CCC addCourseFile", token )
    // dispatch(createModule(courseId, module, token))
  }

  
  const addCoursePrice = (price) => {
    console.log("CCC price",courseId, price )
    dispatch(createCoursePrice(courseId, price, token))
  }
  


//   const editModule = (moduleId, module) => {
//     dispatch(updateModule(courseId, moduleId, module))
//   }

  const removeModule = (moduleId) => {
    if (window.confirm('Are you sure you want to delete this Module?')) {
    dispatch(deleteModule(courseId, moduleId, token))
    }
  }

  const addModuleItem = (moduleId, moduleItem) => {
    console.log("VVVVVVVVVVVVVVV moduleItem", moduleId, moduleItem)
    return dispatch(createModuleItem(courseId, moduleId, moduleItem, token))
    // return
  }

  const removeModuleItem = (moduleId, moduleItem) => {
    if (window.confirm('Are you sure you want to delete this Video?')) {
    dispatch(deleteModuleItem(courseId, moduleId, moduleItem, token))
    }
  }

  const [addModalActive, setAddModalActive] = useState(false)
  const [addUserEnroll, setAddUserEnroll] = useState(false)
  const [addFile, setAddFile] = useState(false)
  const [addPrice, setAddPrice] = useState(false)

  const [form] = Form.useForm()

  const handleCancel = () => {
    setAddModalActive(false)
    setAddFile(false)
    setAddUserEnroll(false)
    setAddPrice(false)
  }

  return (
    <div className='m-10'>
      <FlexSectionHeader>
        <Title level={3}>Modules</Title>
        {/* {enrolled && privilege !== STUDENT && ( */}
        <div style={{display: "flex", gap: 20}}>
        <Button
            onClick={() => setAddUserEnroll(true)}
            type="dashed"
            shape="round"
            icon={<PlusOutlined />}
          >
            Add user to enroll
          </Button>
        <Button
            onClick={() => setAddPrice(true)}
            type="dashed"
            shape="round"
            icon={<PlusOutlined />}
          >
            Price
          </Button>
        <Button
            onClick={() => setAddFile(true)}
            type="dashed"
            shape="round"
            icon={<PlusOutlined />}
          >
            Add File
          </Button>
          <Button
            onClick={() => setAddModalActive(true)}
            type="dashed"
            shape="round"
            icon={<PlusOutlined />}
          >
            Add Module
          </Button>
          </div>
        {/* )} */}
      </FlexSectionHeader>

      <Modal
        title="Add New Module"
        visible={addModalActive}
        onOk={form.submit}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={form.submit}>
            Submit
          </Button>
        ]}
      >
        <Form
          name="add Module"
          form={form}
          onFinish={addModule}
          requiredMark={false}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
        >
          <Form.Item
            name="title"
            label="Course Module"
            rules={[
              {
                required: true,
                message: 'Please enter the module name'
              }
            ]}
          >
            <Input placeholder="Module Name" />
          </Form.Item>
        </Form>
      </Modal>


      <Modal
        title="Add User to Enroll"
        visible={addUserEnroll}
        onOk={form.submit}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={form.submit}>
            Submit
          </Button>
        ]}
      >
        <Form
          name="add User to enroll"
          form={form}
          onFinish={addCourseEnroll}
          requiredMark={false}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
        >
          <Form.Item
            name="email"
            label="user email"
            // rules={[
            //   {
            //     required: true,
            //     message: 'Please enter the user email'
            //   }
            // ]}
          >
            <Input placeholder="email@com" type='email' />
          </Form.Item>

          <Form.Item
            name="multiple_email"
            label="multiple user email"
            // rules={[
            //   {
            //     required: true,
            //     message: 'Please enter the user email'
            //   }
            // ]}
          >
            <Input placeholder="email@com,email@com"/>
          </Form.Item>

          <a className="font-medium text-primary-600 hover:underline dark:text-primary-500">
                  <Link to="/CoursePaidUsers" style={{ color: "crimson" }}>
                     course paid users
                  </Link>
        </a>
        </Form>
      </Modal>

      <Modal
        title="Add File"
        visible={addFile}
        onOk={form.submit}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={form.submit}>
            Submit
          </Button>
        ]}
      >
        <Form
          name="Add File"
          form={form}
          onFinish={addCourseFile}
          requiredMark={false}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
        >
          <Form.Item
            name="title"
            label="Add File"
            rules={[
              {
                required: true,
                message: 'Please enter the module name'
              }
            ]}
          >
            <Input placeholder="Module Name" />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Add Cource Price"
        visible={addPrice}
        onOk={form.submit}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={form.submit}>
            Submit
          </Button>
        ]}
      >
        <Form
          name="add Price"
          form={form}
          onFinish={addCoursePrice}
          requiredMark={false}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
        >
          <Form.Item
            name="price"
            label="price"
            rules={[
              {
                required: true,
                message: 'Please enter the course price'
              }
            ]}
          >
            <Input placeholder="100" type='number' />
          </Form.Item>
        </Form>
      </Modal>

      <div>
        <div  style={{ fontSize: 20 ,fontWeight: 700, marginTop: 20}}>
         {uploadProgress > 0 ? `uploadProgress: ${uploadProgress}%` : ''}
         </div>

      <div style={{ marginTop: '16px' }}>
        <List
          dataSource={modules}
          renderItem={(module) => (
            <List.Item>
              <ModuleList
                module={module}
                instructorAccess={privilege !== STUDENT}
                // editModule={(updatedModule) =>
                //   editModule(module.id, updatedModule)
                // }
                removeModule={removeModule}
                addModuleItem={(moduleItem) =>
                  addModuleItem(module.id, moduleItem)
                }
                removeModuleItem={(moduleItem) =>
                  removeModuleItem(module.id, moduleItem)
                }
              />
            </List.Item>
          )}
        />
      </div>
    </div>
    </div>
  )
}

export default Modules
