import moduleService, { getModuleItemsURL } from '../services/courseModules'
// import s3Service from '../services/s3Service'

import {
  GET_ALL_MODULES,
  CREATE_MODULE,
  UPDATE_MODULE,
  DELETE_MODULE,
  CLEAR_MODULES
} from '../actions/courseModules'

import {
  CREATE_MODULE_ITEM,
  UPDATE_MODULE_ITEM,
  DELETE_MODULE_ITEM,
  LOADING_UPLOAD
} from '../actions/courseModules'

import { notification } from 'antd'
import axios from 'axios'
import { GLOBALTYPES } from '../../redux/actions/globalTypes'

const moduleReducer = (state = [], action) => {
  switch (action.type) {
    case GET_ALL_MODULES:
      return action.data
    case CREATE_MODULE:
      return action.data
    case UPDATE_MODULE:
      return action.data
    case DELETE_MODULE:
      return action.data
    case CREATE_MODULE_ITEM:
      return action.data
    case UPDATE_MODULE_ITEM:
      return action.data
    case DELETE_MODULE_ITEM:
      return action.data
    case LOADING_UPLOAD:
      return state.map((courseModule) => {
        if (courseModule.id === action.moduleId)
          return { ...courseModule, loadingUpload: true }
        else return courseModule
      })
    case CLEAR_MODULES:
      return []
    default:
      return state
  }
}

export const getAllModules = (courseId) => {
  return async (dispatch) => {
    try {
      const response = await moduleService.getAllModules(courseId)
      console.log("RRRRRrrrrrr", response)
      dispatch({ type: GET_ALL_MODULES, data: response })
    } catch (error) {
      console.log(error)
      notification.error({
        message: "Couldn't load Modules check your connection"
      })
    }
  }
}

export const createModule = (courseId, module, token) => {
  return async (dispatch) => {
    try {
      const response = await moduleService.createModule(courseId, module, token)
      // dispatch({ type: CREATE_MODULE, data: response })
      notification.success({
        message: 'Added module successfully'
      })

      setTimeout(() => {
        window.location.reload();
      }, 3000);

    } catch (error) {
      console.log(error)
      notification.error({
        message: "Couldn't add module"
      })
    }
  }
}

export const enrollUser = (courseId, email, token) => {
  return async (dispatch) => {
    try {
      const response = await moduleService.enrollUser(courseId, email, token)
      // dispatch({ type: CREATE_MODULE, data: response })
      notification.success({
        message: 'enroll user successfully'
      })
    } catch (error) {
      notification.error({
        message: error.response.data.msg
      })
    }
  }
}


export const multipleEnrollUser = (courseId, email, token) => {
  return async (dispatch) => {
    try {
      const response = await moduleService.multipleEnrollUser(courseId, email, token)
      // dispatch({ type: CREATE_MODULE, data: response })
      notification.success({
        message: 'enroll users successfully'
      })
    } catch (error) {
      console.log("massage", error.response.data)
      notification.error({
        message: error.response.data.errors
      })
      // notification.error({
      //   message: (
      //     <ul>
      //       {error.response.data.errors.map((error, index) => (
      //         <li key={index}>{error}</li>
      //       ))}
      //     </ul>
      //   )
      // });
    }
  }
}

export const createCoursePrice = (courseId, email, token) => {
  return async (dispatch) => {
    try {
      const response = await moduleService.createCoursePrice(courseId, email, token)
      // dispatch({ type: CREATE_MODULE, data: response })
      notification.success({
        message: 'price added successfully'
      })
    } catch (error) {
      notification.error({
        message: error.response.data.msg
      })
    }
  }
}





export const updateModule = (courseId, moduleId, module) => {
  return async (dispatch) => {
    try {
      const response = await moduleService.updateModule(
        courseId,
        moduleId,
        module
      )
      dispatch({ type: UPDATE_MODULE, data: response })
      notification.success({
        message: 'updated Module successfully'
      })
    } catch (error) {
      console.log(error)
      notification.error({
        message: "Couldn't update Module"
      })
    }
  }
}

export const deleteModule = (courseId, moduleId, token) => {
  return async (dispatch) => {
    console.log("CCC DDDDDD", courseId, moduleId, token )
    // const { modules } = getState()
    // const moduleToDelete = modules.find((module) => module.id === moduleId)

    // for (const moduleItem of moduleToDelete.moduleItems) {
    //   // if (moduleItem.type === 'file') await s3Service.deleteFile(moduleItem.url)
    // }

    try {
      const response = await moduleService.deleteModule(courseId, moduleId, token)
      // dispatch({ type: DELETE_MODULE, data: response })
      notification.success({
        message: 'deleted Module successfully'
      })

      setTimeout(() => {
        window.location.reload();
      }, 3000);

    } catch (error) {
      console.log(error)
      notification.error({
        message: "Couldn't delete Module"
      })
    }
  }
}

export const createModuleItem = (courseId, moduleId, moduleItem, token) => {
  return async (dispatch) => {
    try {
      // if (moduleItem instanceof FormData) {
      //   dispatch({ type: LOADING_UPLOAD, moduleId })

      //   const fileURL = await s3Service.uploadFile(
      //     courseId,
      //     'modules',
      //     moduleItem.get('file').name,
      //     moduleItem.get('file')
      //   )

      //   moduleItem.append('url', fileURL)
      // }

      // const response = await moduleService.createModuleItem(
      //   courseId,
      //   moduleId,
      //   moduleItem,
      //   token
      // )
      // console.log("getModuleItemsURL(courseId, moduleId),", getModuleItemsURL(courseId, moduleId))

      const response = await axios.post(
        getModuleItemsURL(courseId, moduleId),
        moduleItem,
        // getAuthHeader(token),
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`
          },
          // onUploadProgress: (progressEvent) => {
          //   const total = progressEvent.total || 1; // Handle potential undefined total
          //   const progress = Math.round((progressEvent.loaded * 100) / total);
          //   console.log("upload progress:", progress, "%"); // Log with informative message
          //   dispatch({ type: GLOBALTYPES.UPLOAD, payload: { uploadModule: progress } });
          // },
        }
      )

      
      notification.success({
        message: 'Added module Item successfully'
      })

      setTimeout(() => {
        window.location.reload();
      }, 3000);

      // dispatch({ type: CREATE_MODULE_ITEM, data: response })
    } catch (error) {
      console.log(error)
      notification.error({
        message: "Couldn't add module Item"
      })
    }
  }
}

export const deleteModuleItem = (courseId, moduleId, moduleItem, token) => {
  return async (dispatch) => {
    try {
      // if (moduleItem.type === 'file') await s3Service.deleteFile(moduleItem.url)

      const response = await moduleService.deleteModuleItem(
        courseId,
        moduleId,
        moduleItem.id,
        token
      )
      notification.success({
        message: 'deleted Module Item successfully'
      })
      // dispatch({ type: DELETE_MODULE_ITEM, data: response })

      setTimeout(() => {
        window.location.reload();
      }, 3000);
      
    } catch (error) {
      console.log(error)
      notification.error({
        message: "Couldn't delete module Item"
      })
    }
  }
}

export const clearModules = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: CLEAR_MODULES })
    } catch (error) {
      console.log(error)
      notification.error({
        message: "Couldn't clear modules"
      })
    }
  }
}

export default moduleReducer
