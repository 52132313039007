import axios from 'axios'
import { getAuthHeader } from './config'
import { BASE_URL } from '../../utils/config'

// export const baseURL = '/courses'
// export const baseURL = 'http://localhost:8000/api/course'
export const baseURL = `${BASE_URL}/api/course` 

const getAllCourses = async (token) => {
  const response = await axios.get(`${baseURL}/get_course/`, {
    ...getAuthHeader(token)
    // params: { filter: 'published' }
  })
  return response.data
}

const getOneCourse = async (courseId) => {
  const response = await axios.get(`${baseURL}/${courseId}`, getAuthHeader())
  return response.data
}

const updateCourse = async (courseId, course) => {
  const response = await axios.put(
    `${baseURL}/${courseId}`,
    course,
    getAuthHeader()
  )
  return response.data
}

const endCourse = async (courseId) => {
  const response = await axios.post(
    `${baseURL}/${courseId}/end-course`,
    null,
    getAuthHeader()
  )
  return response.data
}

const createCourse = async (course, token) => {
  console.log("add course", course, token)
  console.log("add course baseURL", baseURL)
  const response = await axios.post(`${baseURL}/create-course/`, course, getAuthHeader(token))
  return response.data
  
}

const deleteCourse = async (courseId, token) => {
  const response = await axios.delete(`${baseURL}/delete_course/${courseId}`, getAuthHeader(token))
  return response.data
}

const enrollCourse = async (courseId, userId) => {
  const response = await axios.post(
    `${baseURL}/${courseId}/enroll`,
    { userId },
    getAuthHeader()
  )
  return response.data
}

const UnenrollCourse = async (courseId, userId) => {
  const response = await axios.post(
    `${baseURL}/${courseId}/un-enroll`,
    { userId },
    getAuthHeader()
  )
  return response.data
}

const courseService = {
  getAllCourses,
  getOneCourse,
  createCourse,
  updateCourse,
  deleteCourse,
  enrollCourse,
  UnenrollCourse,
  endCourse
}
export default courseService
