// export const BASE_URL = 'http://localhost:8000'


export const socket_URL = 'ws://localhost:8000'

// export const BASE_URL = 'http://localhost:8000'

export const BASE_URL = "https://back2.zsecretacademy.com"

// export const socket_URL = 'ws://192.168.0.4:8000'


// export const BASE_URL = process.env.REACT_APP_API_KEY



export const Media_URL = "https://media.zsecretacademy.com"
// export const Media_URL = 'http://localhost:4000'