import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { Empty, Row, Col, Space } from 'antd'

import Video from './components/Video'
import VideoInfo from './components/VideoInfo'
import PlaylistMenu from './components/PlaylistMenu'
import LectureComments from './components/LectureComments'
import { moduleItems } from '../../data/course_data'

import axios from 'axios'

import { getAllLectures } from '../../redux2/reducers/lectureReducer'
import Spinner from '../../edu-hub/components/Spinner'

const iso8601Duration = (duration) => {
  const durationRegex =
    /(-)?P(?:([.,\d]+)Y)?(?:([.,\d]+)M)?(?:([.,\d]+)W)?(?:([.,\d]+)D)?T(?:([.,\d]+)H)?(?:([.,\d]+)M)?(?:([.,\d]+)S)?/

  const matches = duration.match(durationRegex)

  // seconds
  if (!matches[7]) return `${matches[8]}`
  // min:seconds
  if (!matches[6]) return `${matches[7]}:${matches[8]}`
  // hours:mins:seconds
  if (!matches[5]) return `${matches[6]}:${matches[7]}:${matches[8]}`
}

const selectLecture = (lectures, lectureId) => {
  if (!Array.isArray(lectures) || !lectures.length) return null
  if (!lectureId) return lectures[0]
  const index = lectures.findIndex((lecture) => lecture.id == lectureId)
  console.log("indexXXXXX", index)
  if (index === -1) return null
  return lectures[index]
}

const LecturePage = (props) => {
  const { lectures } = props
  const { lectureId } = useParams()

  const [selectedLecture, setSelectedLecture] = useState(
    selectLecture(lectures, lectureId)
  )

  if (!selectedLecture) return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />

  const chooseLecture = (lectureId) => {
    setSelectedLecture(selectLecture(lectures, lectureId))
  }

  console.log("selectedLecture_____________________________", selectedLecture)

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} xl={18}>
          <Space size="middle" direction="vertical" style={{ width: '100%' }}>
            <Video selectedLecture={selectedLecture}></Video>
            <VideoInfo lecture={selectedLecture} />
            <LectureComments selectedLecture={selectedLecture} />
          </Space>
        </Col>

        <Col xs={24} sm={24} xl={6}>
          <PlaylistMenu
            lectures={lectures}
            selectedLecture={selectedLecture}
            chooseLecture={chooseLecture}
          ></PlaylistMenu>
        </Col>
      </Row>
    </>
  )
}

const Lectures = () => {
  const dispatch = useDispatch()

  const { courseId } = useParams()


  useEffect(() => {
    dispatch(getAllLectures(courseId))
  }, [courseId, dispatch])


  // // const [lectures, setLectures] = useState({});

  // //  useEffect(() => {
  // //   setLectures(getLecturesData("onSSLxGyrnw,x1lhGscIRkU,_UQiJgJnSEU"))
  // //   }, [])

  // const getLecturesData = async (videoIds2) => {

  //   // const videoIds2 = response.reduce((accumulator, item, index) => {
  //   //   if (index === 0) return accumulator.concat(`${item.videoId}`)
  //   //   return accumulator.concat(`,${item.videoId}`)
  //   // }, '')
  //   const response = moduleItems

  //   console.log("modleule response  11111111", response)
  //   const videoIds = response.reduce((accumulator, item, index) => {
  //     if (index === 0) return accumulator.concat(`${item.videoId}`)
  //     return accumulator.concat(`,${item.videoId}`)
  //   }, '')

  //   const youtuberesponse = await axios.get(
  //     'https://youtube.googleapis.com/youtube/v3/videos',
  //     {
  //       params: {
  //         part: 'snippet,contentDetails',
  //         id: videoIds,
  //         // key: process.env.REACT_APP_YOUTUBE_API_KEY
  //         key: 'AIzaSyDdb5ritWnaVb4dCvOXA4XFJPWbqjOEK6w'
  //       }
  //     }
  //   )


  //   console.log("response RRRRRRRR", youtuberesponse)

  //   let data

  //   if (videoIds) {
  //     data = youtuberesponse.data
  //     console.log("inideDDDD", data.items[0].snippet.thumbnails.medium)
  //     for (let i = 0; i < data.items.length; i++) {
  //       response[i].thumbnail = data.items[i].snippet.thumbnails.medium
  //       response[i].youtubeDescription = data.items[i].snippet.description
  //       response[i].youtubeTitle = data.items[i].snippet.title
  //       response[i].publishedAt = data.items[i].snippet.publishedAt
  //         .slice(0, 10)
  //         .replaceAll('-', '/')
  //       response[i].duration = iso8601Duration(
  //         data.items[i].contentDetails.duration
  //       )
  //     }
  //   }

  //   console.log("response.data FFFFF", response)

  //   return response.data
  // }

  const loading = useSelector((state) => state.lectures.loading)
  const lectures = useSelector((state) => state.lectures.data)

  if (loading) {
    return <Spinner size="large" />
  }
  // let lectures =[
  //   {
  //     duration: "12:39:45",
  //     id: "669946cd4ecc7e65fc13b384",
  //     publishedAt: "2020/04/20",
  //     thumbnail: {
  //       height: 180,
  //       url: "https://i.ytimg.com/vi/f1R_bykXHGE/mqdefault.jpg",
  //       width: 320
  //     },
  //     title: "maths unit",
  //     type: "video",
  //     url: "https://youtu.be/f1R_bykXHGE?list=RDCMUCWEHue8kksIaktO8KTTN_zg",
  //     videoId: "f1R_bykXHGE",
  //     youtubeDescription: "... (long description string)", // Truncated for brevity
  //     youtubeTitle: "Create a Twitter-like App with Python Django JavaScript and React. Full TUTORIAL"
  //   }
  // ]

  return( 
  <>
  <LecturePage lectures={lectures} />
  </>
  )
}

export default Lectures
